.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='9px']::before {
  content: '9';
  font-size: 9px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='10px']::before {
  content: '10';
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='12px']::before {
  content: '12';
  font-size: 11px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='14px']::before {
  content: '14';
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='16px']::before {
  content: '16';
  font-size: 13px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='20px']::before {
  content: '20';
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='24px']::before {
  content: '24';
  font-size: 15px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='32px']::before {
  content: '32';
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='42px']::before {
  content: '42';
  font-size: 17px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='54px']::before {
  content: '54';
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='68px']::before {
  content: '68';
  font-size: 19px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='84px']::before {
  content: '84';
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value='98px']::before {
  content: '98';
  font-size: 22px !important;
}
