.multiline-header {
  white-space: normal !important;
  word-wrap: normal !important;
  height: auto !important;
  line-height: normal !important;
  text-wrap: normal !important;
  text-overflow: clip !important;
  text-align: center !important;
  vertical-align: middle !important;
}

.ajustar-fonte {
  font-size: 0.8em !important;
  vertical-align: middle !important;
}

.buttom-selecionar :hover {
  color: #2e7d32 !important;
}

.text-right {
  text-align: right !important;
}
